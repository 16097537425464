import ApiService from "./ApiService"


function setUserTimezoneIfNeeded() {
  if (IS_AUTHENTICATED === true) {
    const currTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (currTimezone !== USER_TIMEZONE) {
      ApiService.userSetTimezone(currTimezone);
    }
  }
}

// Run on every page load
$(document).ready(function() {
  setUserTimezoneIfNeeded();
});
